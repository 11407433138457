(function () {
	'use strict';
	angular
		.module('app')
		.factory('groupSelector', [
			'firebaseIO',
			'utilities',
			'hash',
			'seedcodeCalendar',
			groupSelector,
		]);

	function groupSelector(firebaseIO, utilities, hash, seedcodeCalendar) {
		return {
			changeGroupPopover: changeGroupPopover,
			buildGroupList: buildGroupList,
		};

		function changeGroupPopover(callback) {
			const user = seedcodeCalendar.get('user');
			const userID = user.id;
			const groupID = user.group.id;

			firebaseIO.getUserData(userID, 'user', processUser);
			function processUser(user) {
				const hashData = hash.get();
				const subscription = {
					type: hashData.type,
				};

				const popover = {
					id: 'groupPopover',
					controller: 'GroupSelectorCtrl',
					container: $('body'),
					type: 'modal', // modal or popover
					width: 600,
					data: {
						selectedGroup: groupID,
						editMode: false,
						user: user,
						subscription: subscription,
						loading: false,
					},
					dataTitle: 'data',
					destroy: true,
					onShow: '',
					onShown: '',
					onHide: '',
					onHidden: '',
					show: true,
					callback: callback,
				};

				utilities.popover(
					popover,
					'<div ng-include="\'app/user/group-selector/my-groups.html\'"></div>'
				);
			}
		}

		/** @type {(callback: Function) => void} */
		function buildGroupList(callback) {
			// Callback returns array of groups
			let groupCounter = 0;
			let sourceCounter = 0;
			const groupList = [];
			const user = seedcodeCalendar.get('user');

			// Get list of all groups user has access to
			groupList.push(JSON.parse(JSON.stringify(user.primaryGroup)));
			for (let group in user.additionalGroups) {
				groupList.push(
					JSON.parse(JSON.stringify(user.additionalGroups[group]))
				);
			}

			// Loop through groups and get group metadata
			for (let i = 0; i < groupList.length; i++) {
				groupCounter++;
				sourceCounter++;

				// Get group metadata (for group name)
				firebaseIO.getOtherGroupData(
					groupList[i].id,
					'group',
					(groupResult) => {
						processGroup(groupResult, i);
					}
				);
				firebaseIO.getOtherGroupData(
					groupList[i].id,
					'sources',
					(sources) => {
						processSources(sources, i);
					}
				);
			}

			// Define callback function
			function processGroup(group, groupListIndex) {
				groupList[groupListIndex].name = group.name;
				groupCounter--;
				callbackCheck();
			}

			function processSources(sources, groupListIndex) {
				groupList[groupListIndex].hasFilemakerSource =
					hasFilemakerSource(sources);
				sourceCounter--;
				callbackCheck();
			}
			function callbackCheck() {
				if (groupCounter <= 0 && sourceCounter <= 0) {
					callback(groupList);
				}
			}
		}

		/** @type {(sources: Object) => boolean} */
		function hasFilemakerSource(sources) {
			console.log('sources', sources);
			// Loop through sources in the group and return true if there is a filemaker source (type 8)
			for (const sourceID in sources) {
				console.log('source', sources[sourceID]);
				if (sources[sourceID].sourceTypeID === 8) {
					return true;
				}
			}
			return false;
		}
	}
})();
